import Axios from "axios";
import constants from "./constants_epics";
import { store } from "../redux";
import { Redux } from "..";
// import { injectIntl } from "react-intl";
// import CSocket from "../socket";
// import { toast } from "react-toastify";
// import Swal from "sweetalert2/src/sweetalert2.js";
// const hyperid = require("hyperid");
import io from "socket.io-client"; // Import the socket.io-client


const defaultPrefs = {
  setUserId: true,
  setReqId: true,
  setMachineId: true,
  setLang: true,
  setOperatorId: true,
  setToken: false,
};

// Initialize the socket connection
const socket = io("wss://insckt.ibaterm.com", {
  transports: ["websocket"],
  autoConnect: false,
});

socket.on("data", (data) => {
  try {
    if (!data) {
      console.log("Received null or undefined data");
      return;
    }
      // Eğer 'data' anahtarı varsa
      if (data && data.msg && data.msg.type === "moneyup") {
        if (data.msg.terminalId == localStorage.getItem("TerminalId")) {
          console.log('getuser calisti');
          getUserData();
        }
      }
   
  } catch (error) {
    console.log("Error parsing JSON:", error);
  }
});

socket.on('disconnect', () => {
  console.log('Socket disconnected, attempting to reconnect...');
  setTimeout(() => {
    socket.open();
  }, 1000); // Retry every second
});

socket.open();


function emitTerminalMessage(action) {
  let message = {};
  if (action === "logout") {

     message =  {
      msg: {
        terminalId: localStorage.getItem("TerminalId"),
        type: "logout"
      }}
    
}
  socket.emit('terminal', message);

}

 function emitTerminalMessageremote(action, data = {}) {
  let message = {
    msg: {
      terminalId: localStorage.getItem("TerminalId"),
      type: action,
      ...data
    }
  };
  socket.emit('terminal', message);
}


class WebClient {

  
  constructor(defaultConfig) {
    defaultConfig = {
      baseURL: constants.base_url,
      headers: {
        "Content-Type": "application/json",
      },
    };

    this.axiosInstance = Axios.create(defaultConfig);
  }

  get = (path) => {
    const realPromise = this.axiosInstance.get(path);
    return this.createProxyPromise(realPromise);
  };

  post = (path, data, prefs = defaultPrefs, showLoading = true) => {
    const urlParams = new URLSearchParams(window.location.search);
    const TerminalId = urlParams.get("TerminalId") || "";

    if(!localStorage.getItem("TerminalId") || TerminalId != localStorage.getItem("TerminalId"))
    {
      localStorage.setItem("TerminalId", TerminalId);
    }
    const states = store.getState();

    let d = { ...data, site_id: states.settings.site_id };

    if (prefs?.setLang) {
      d = { ...d, lang: states.language };
    }

    if (prefs?.setMachineId) {
      d = { ...d, machineId: localStorage.getItem("TerminalId") };
    }

    if (prefs?.setReqId) {
      d = { ...d, reqId: constants.reqId };
    }

    if (prefs?.setUserId) {
     
      if (prefs?.setUserId) {
        const userId = states.member?.userId || "0";
        d = { ...d, userId };
      }
    }

    if (prefs?.setOperatorId) {

      d = { ...d, operatorId: states.settings.operatorId};
    }

    if (prefs?.setToken) {
      d = { ...d, token: Math.random().toString(36).substring(2) };
    }

    if (prefs?.setTokenWithUser) {
      d = { ...d, token: states.member.userId };
    }

    if (prefs?.setServerId) {
      d = { ...d, serverId: states.settings.serverid };
    }

    const realPromise = this.axiosInstance.post(path, d);
    return this.createProxyPromise(realPromise, showLoading);
  };

  createProxyPromise(realPromise, showLoading) {
    if (showLoading) {
      Redux.showLoading();
    }

    return new Promise((resolve, reject) => {
      realPromise
        .then((data) => {
          this.responseHandler(data, resolve, reject);
        })
        .catch((error) => {
          this.errorHandler(error, reject);
        });
    });
  }

  responseHandler = async (response, callback, reject) => {
    Redux.hideLoading();
    callback(response.data);
  };

  errorHandler = async (error, reject) => {
    Redux.hideLoading();

    // if (error.response === undefined) {
    //     setError()
    //     return;
    // }

    if (error.response !== undefined && error.response.status === 401) {
      // toast.error("Oturum süreniz doldu veya sonlandı! Lütfen tekrar giriş yapmayı deneyin.")
      // logout()
    } else if (error.response !== undefined && error.response.status === 405) {
      // toast.error("İstek metodu hatalı!")
    } else if (error.response !== undefined && error.response.status === 403) {
      // toast.error(error.response.data.message)
    } else {
      // reject(error);
    }

    reject(error);
  };
}

function getUserData(type = 1, uid = null) {
  let userId = "";
  userId = type === 2 ? uid : store.getState().member.userId;

  localStorage.setItem("userId", userId);

  const wc = new WebClient();
  wc.post(
    constants.uris.data,
    { userId },
    {
      setReqId: true,
      setLang: true,
    }
  )
    .then((response) => {
      if (response.status) {
        Redux.setMemberData({ userId, ...response });
        if(response?.terminalNo) localStorage.setItem("TerminalId", response.terminalNo);
   
        if (type === 2) getBingoUrl();
      } else {
        if(response.status === false && response.logout === true)
        {
  
          emitTerminalMessage("logout");
        }
        Redux.setReady(true);
        Redux.showToast("warning", response.error);
      }
    })
    .catch((error) => {
      Redux.setReady(true);
      Redux.showToast("error", "An error occurred");
    });
}

function LangChanger(lang = 'en') {


  const wc = new WebClient();
  wc.post(
    constants.uris.lngcnhg,
    {
      setReqId: true,
      setLang: true,
      setUserId: true,
    }
  )
    .then((response) => {
      if (response.status) {
      



        
      } else {
        Redux.setReady(true);
        Redux.showToast("warning", response.error);
      }
    })
    .catch((error) => {
      Redux.setReady(true);
      Redux.showToast("error", "An error occurred");
    });
}

async function getBingoUrl() {
  const wc = new WebClient();
  wc.post(
    constants.uris.bngo,
    {
      token: Math.random().toString(36).substr(2),
    },
    {
      setReqId: true,
      setMachineId: false,
      setUserId: true,
      setLang: true,
    }
  ).then((response) => {
    if (!response.status) {
      return;
    }
    const config = store.getState().config;

    const video = config.settings.secondScreen === "TombalaVideo" ? 0 : 1;

    const gameUrl = `${response.content.gameurl}&terminal=1&video=${video}&sendData=1&origin=${window.location.origin}`;

    Redux.setBingoUrl(gameUrl);

    if (config.settings.secondScreen === "TombalaVideo") {
      const a = response?.content?.gameurl?.split("?token");
      if (a?.length > 0) {
        const gameUrlForSecondScreen = `${a[0]}/#/secondScreen?token${a[1]}`;
        localStorage.setItem("bingoUrl", gameUrlForSecondScreen);
      }
    }

    if (window?.electronAPI?.ipcRenderer) {
      window.electronAPI.ipcRenderer.send("loginSucceeded", "_");
    }
  });
}

function SetConnectionListener() {
  window.addEventListener("online", onlineStatusChange);
  window.addEventListener("offline", onlineStatusChange);
}

function onlineStatusChange() {
  const a = store.getState().settings;
  Redux.setConnectionError(!navigator.onLine);
  if (navigator.onLine && a === null) {
    GetSettings();
  }

  if (navigator.onLine) {
    window.electronAPI.ipcRenderer.send("OpenCoinService", "_");
  } else {
    window.electronAPI.ipcRenderer.send("CloseCoinService", "_");
  }
}

function GetSettings(onSuccess = () => {}, onError = () => {}) {
  const cc = new WebClient();
  cc.get("https://data.apiepics.com/v1/settings/get?domain=pino24.com")
    .then((response) => {
      if (response?.generalJs) {
        const script = document.createElement("script");
       //  script.src = `${constants.base_url}/${response.generalJs}`;
       script.src = "/settings/getsettingslocal.js"
        script.async = true;
        document.body.appendChild(script);
      }
      var site_interval = setInterval(() => {
        if (!window?.site_id) return;
        clearInterval(site_interval);
        constants.base_url = `https://api.ibaterm.com`;
   
    
       Redux.setSettings({ ...response, site_id: window?.site_id || null, operatorId: response.operatorId });
     
       
        onSuccess();
        SetConnectionListener();
      }, 100);
    })
    .catch((error) => {
      Redux.showToast("error", "Ayarlar alınamadı. Lütfen daha sonra tekrar deneyin.");
      onError();
      SetConnectionListener();
    });
}


function tF(val) {
  const w = window?.settings?.toFixedNumber ?? 2;
  if (parseFloat(val) > 1.001 && parseFloat(val) < 1.009)
    return parseFloat(val).toFixed(3);
  return parseFloat(val).toFixed
(w)
;
}

export { WebClient, constants, getUserData, LangChanger, GetSettings, tF,emitTerminalMessageremote  };