import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import LiveMatchRow from './live-match-row'
import { VariableSizeList as List } from 'react-window';

class MatchList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            sc: props.sc,
            onlyFavorites: props.onlyFavorites,
            schema: props.schema
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps?.sc != this.state.sc) {
            this.setState({sc: nextProps.sc})
            // this.list.resetAfterIndex(0)
            return true
        }

        if (nextProps?.schema != this.state.schema) {
            this.setState({schema: nextProps.schema})
            return true
        }        

        if (nextProps?.onlyFavorites != this.state.onlyFavorites) {
            this.setState({onlyFavorites: nextProps.onlyFavorites})
            return true
        }

        return false;
    }

    render() {
        const { live_matches, schema, z, l, orderBy, showAllList, halfTime, dh } = this.props

        let matches = live_matches
        
        const row_height = 58;
        const row_height_fh = 116;

        matches = _.orderBy(matches, ['tio'], ['desc'])

        return (
            <>
            {
            matches.map((item, index) => {
                return (
                    <LiveMatchRow 
                        key={item.id}
                        z={z} 
                        l={l} 
                        schema={schema} 
                        item={item} 
                        oe={index%2 == 0 ? "odd" : "even"} 
                        halfTime={halfTime} 
                        onShowStatistics={this.props.onShowStatistics} 
                        onAddFavorite={this.props.onAddFavorite}
                        onDetail={this.props.onDetail}
                    />                
                )
            })
            }
            </>
        )        
    }
}

const msp = (state) => {
    return {
        config: state.config,
        member: state.member,
        live_matches: state.live_matches,
        sc: state._statusChanged
    }
  }
  
  export default connect(msp)(MatchList)
