import React from 'react'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown, getUserData } from '../../../components'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import { FlatList } from 'react-native'
import moment from 'moment'
import CouponNumpad from './components/coupon-numpad'
import CouponMatch from './components/coupon-match'
import _, { uniq } from "lodash"
import { toast } from 'react-toastify'
import Swal from 'sweetalert2/src/sweetalert2.js'

let listener_initialized = false

class Coupon extends React.Component {

    static defaultProps = {
        hideClose: false
    }

    constructor(props) {
        super(props)
        this.wc = new WebClient();
        this.state = {
            showCouponTypeModal: false,
            activeAmountTrigger: 1,
            betAmount: 1,
            selectedCombine: 0,
            selectedCombinations: [],
            tempSelectedCombinations: [],
            showCouponNumpad: false,
            coupon: props.coupon,
            lock: true,
            changed: []
        }
    }

    componentDidMount() {

    }

    _checkPrinterStatus() {

        Redux.calculateCoupon(this.props.intl)
        if (this.props.ps) {
            this._createCoupon()
        } else {
            toast.error(this.props.intl.formatMessage({id: "lang_printer_not_ready", defaultMessage: "lang_printer_not_ready"}))
        }

    }

    resetTrigger() {
        Redux.updateCouponBottom({bet_trigger: 1, bet_amount: 1})
    }

    addAmount(a) {

        let c = parseFloat(this.props.coupon_bottom.bet_amount)
        let b = c + a        
        let d = (b/a)%1

        if (d == 0) b = a + c
        else b = a

        if (b > this.props.member.userCredit) b = parseFloat(this.props.member.userCredit)
        Redux.updateCouponBottom({bet_amount: b})
    }
    subtractAmount() {
        let c = parseFloat(this.props.coupon_bottom.bet_amount)
        let a = -1
        let b = c + a        
        let d = (b / a) % 1
    
        if (d === 0) b = a + c
        else b = a
    
        if (b < 1) b = 1 // Ensure the amount does not go below 1
        Redux.updateCouponBottom({ bet_amount: b })
    }
    _openCouponTypes() {
        let a = this.props.combinations.filter(x => x.selected == true)
        this.setState({showCouponTypeModal: true, selectedCombinations: a})
    }

    addOrRemoveCombination(item) {
        
        let a = [...this.state.selectedCombinations]
        let j = a.indexOf(item)
        
        if (j != -1) {
            a.splice(j, 1)
        } else {
            a.push(item)
        }
        
        this.setState({selectedCombinations: a})
    }    

    confirmCombinations() {
        Redux.addOrRemoveSelectedCombination(this.state.selectedCombinations, this.props.intl)
        this.setState({showCouponTypeModal: false})
    }

    _setBanko(is_selected, item, index) {
        const { coupon, intl } = this.props

        let mbc = coupon.length - 2;
        let bc = coupon.filter(x => x?.is_banko === true)?.length ?? 0

        if (bc < mbc || is_selected) {
            Redux.setOddBanko(index, intl)
        }

    }  

    getSystemText() {
        let z = this.props.combinations.filter(x => x.selected == true)
        let a = []
        let b = _.orderBy(z, ['combine'],['asc']);
        b.forEach((e) => {
            a.push(e.combine)
        })
        return a.join(" + ")
    }

    _createCoupon() {

        const { coupon, coupon_bottom, combinations } = this.props

        let ticket_type = coupon_bottom.type
        let tips = []

        coupon.forEach((e) => {
            e.odd.forEach((o) => {
                tips.push({
                    banko: e?.is_banko ? 1 : 0,
                    oddTypeId: o.ot,
                    betstts: e.item.bs,
                    catId: o.categoryId,
                    halftime: (e.item.l && e.item.ms?.includes("pause")) ? "1" : "0",
                    liveOddtypeId: o.ot,
                    ttype: e.item.l ? "L" : "N",
                    lock: 1,
                    mDate: moment.utc(e.item.md).format('YYYY-MM-DD HH:mm:ss'),
                    matchstatus: e.item.ms,
                    matchtime: e.item?.ti ?? 0,
                    mid: e.item.id,
                    mtip: 1,
                    odd: parseFloat(o.o),
                    sid: e.item.sid,
                    team1: e.item.h,
                    team2: e.item.a,
                    oddTypeStatus: 1,
                    outComeStatus: 1,
                    score: e.item.l ? `${e.item.scr.h}:${e.item.scr.a}` : "0:0",
                    catId: e.item.cid,
                    tourId: e.item.tid,
                    ktip: {
                        betTotal: 5000,
                        kombiMaxPay: 100000,
                        kombiMaxStake: 10000,
                        kombiMinStake: 1,
                        liveMaxPay: 100000,
                        liveMaxStake: 10000,
                        liveMinStake: 1,
                        ttype: e.item.l ? "L" : "N",
                        oddsType: o.ot,              
                        oddid: o.sid,
                        special: o.sp,
                        active: 1,
                        slcted: 1,
                        sportId: e.item.sid,
                        categoryId: e.item.cid,
                        tournamentId: e.item.tid,
                        onSystemOdd: o.o,
                        odds: o.o,
                        minTip: 1,
                        odds: o.o.toString(),
                        outCome: o.out,
                        outComeId: o.id,
                        outcomeid: o.id,
                        singleMaxPay: 100000,
                        singleMaxStake: 10000,
                        singleMinStake: 1,
                      }                     
                })
            })

        })

        let systemTickets = []

        if (coupon_bottom.type == "SK") {
            systemTickets = combinations.filter(a => a.selected === true).reduce((a, o) => (a.push(o.combine), a), [])
        }
        

        let d = {
            ticketStake: coupon_bottom.bet_amount.toFixed(2).toString(),
            totalStake: (coupon_bottom.bet_amount * coupon_bottom.coupon_number).toFixed(2).toString(),
            ticketType: ticket_type,
            couponType: ticket_type == "KK" || ticket_type == "TK" ? 0 : 0,
            systemTickets,
            oddChangeOk: this.state.lock ? 1 : 0,
            tips
        }

        this.wc.post(constants.uris.crcp, d, 
        {
            setLang: true,
            setOperatorId: true,
            setUserId: true,
            setServerId: true,
            setToken: true
        }).then((response) => {
            if (response.status) {
                // Redux.showToast("success", response.msg)  

                Swal.fire({
                    title: this.props.intl.formatMessage({id: "lang_success", defaultMessage: "lang_success"}),
                    text: response.msg,
                    icon: 'success',
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false
                })

                let ticketId = response.ticketId
                this._sendCouponToPrint(ticketId)                

                getUserData(1, this.props.member.userId)

                Redux.emptyCoupon()
                this.props.onClose()
            } else {

                if (response.changed?.length > 0) {
                    Redux.setCouponChangedList(response.changed)
                } else if (response.couponErrors?.length > 0) {
                    Redux.setCouponErrorList(response.couponErrors)
                } else {
                    Swal.fire({
                        title: this.props.intl.formatMessage({id: "lang_warning", defaultMessage: "lang_warning"}),
                        text: response.msg,
                        icon: 'warning',
                        timer: 1500,
                        showCancelButton: false,
                        showConfirmButton: false
                    })  
                }

            }
        })
    }

    _sendCouponToPrint(id) {

        if (window?.electronAPI?.ipcRenderer) {
            let url = constants.prints.couponprint.replace("{{couponId}}", id).replace("{{lang}}", this.props.language)

             console.log("url -->", url)
            /* PRINT COUPON */
            window.electronAPI.ipcRenderer.send("printUrl", {
                url,
                filename: `ticket_coupon_${id}`,
                type: 'ticket',
                couponId: id
            })
            /* PRINT COUPON */
        }

    }

    _reset() {
        this.setState({selectedCombinations: [], tempSelectedCombinations: []})
    }

    _setActiveTrigger(i) {
        Redux.updateCouponBottom({bet_trigger: i})
    }

    render() {
        const { showCouponTypeModal, selectedCombinations, showCouponNumpad, lock } = this.state
        const { intl, coupon_settings, coupon, hideClose, coupon_bottom, combinations, selected_combinations, coupon_changed, coupon_errors } = this.props
        
        const cl = coupon.length
        let disabled = Boolean(cl == 0) || Boolean(coupon_changed > 0)

        return (
        <div className="coupon-cont">
            <div className="coupon-bg-image"></div>
            <div className="coupon-in">
            {
            !hideClose && (
                <button onClick={() => { this.props.onClose() }} className="close-coupon"><FormattedMessage id="lang_close" defaultMessage="lang_close" /></button>
            )
            }

            <div className={classNames({"coupon-title": Boolean(coupon.length > 0), "coupon-title-detail": Boolean(coupon.length == 0)})}>

                {
                coupon.length > 0 && (
                <>
                <FormattedMessage id={Redux.getCouponTypeName(coupon_bottom.ltype)} defaultMessage={Redux.getCouponTypeName(coupon_bottom.ltype)} /> 

                <font style={{marginLeft: 5}}>({coupon_bottom.odd_count})</font>
                
                <span onClick={() => { this._reset(); Redux.emptyCoupon() }}><i className="fas fa-times" /></span>
                </>
                )
                }

                {
                coupon.length == 0 && (
                    <FormattedMessage id="lang_betting_coupon" defaultMessage="lang_betting_coupon" /> 
                )
                }

            </div>

            <div className={classNames({"coupon-middle": !hideClose, "coupon-middle-2": hideClose})}>

                {
                (coupon.length == 0) && (
                    <span className="empty-coupon"><FormattedMessage id="lang_empty_coupon" defaultMessage="lang_empty_coupon" /></span>
                )
                }

                {
                coupon.length > 0 && (
                <div ref={(ref) => this.sl = ref} className="hide-scrollbar w-full h-full overflow-x-scroll">
                    {
                    coupon.map((row, index) => {
                        const { item, odd, unique, matchid } = row
                        let is_live = Boolean(item?.matchstatus)
                        let locked = false
                
                        if (Boolean(item.iss == true || item.isv == false || item.betstatus == "stopped")) {
                            locked = true
                        }

                        let a = coupon_changed.list.filter(x => x.matchid == item.id)

                        if (a.length > 0) {
                            locked = true
                        }

                        let b = coupon_errors.list.filter(x => x.mid == item.id)

                        if (b.length > 0) {
                            locked = true
                        }         
                
                        if (odd.find(x => x.lck)) locked = true 

                        return (
                            <CouponMatch 
                                key={index}
                                unique={unique}
                                item={item} 
                                odd={odd}
                                locked={locked}
                                combinations={combinations}
                                is_live={is_live}
                                index={index}
                                reset={() => { this._reset() }}
                            />
                        )
                    })
                    }
                </div>
                )
                }

            </div>
            
            {
            coupon.length >= 0 && (
            <>
            <UpDown 
                mb="mb-0" 
                upClick={() => { this.sl.scrollTo({ behavior: "smooth", top: this.sl.scrollTop - 100 }) }} 
                downClick={() => { this.sl.scrollTo({ behavior: "smooth", top: this.sl.scrollTop + 100 }) }} 
            />
            <div className="coupon-bottom">

                {
                (coupon_bottom.ltype == "CK" || coupon_bottom.ltype == "TK" || coupon_bottom.ltype == "LK") ? 
                <div className="coupon-type">
                    <FormattedMessage id={Redux.getCouponTypeName(coupon_bottom.ltype)} defaultMessage={Redux.getCouponTypeName(coupon_bottom.ltype)} />
                </div>            
                :
                <div className="coupon-type">

                    {
                    (coupon_bottom.ltype == "TK") && (
                        <FormattedMessage id="lang_single_coupon" defaultMessage="lang_single_coupon" />
                    )
                    }

                    {
                    (coupon_bottom.ltype == "KK" && coupon.length > 1) && (
                        <FormattedMessage id="lang_combine_coupon" values={{count: coupon.length}} defaultMessage="lang_combine_coupon" />
                    )
                    }

                    {
                    (coupon_bottom.ltype == "SK") && (
                        <FormattedMessage id="lang_system_coupon" defaultMessage="lang_system_coupon" values={{p1: this.getSystemText(), p2: coupon.length, p3: coupon_bottom.coupon_number}} />
                    )
                    }

                    <div onClick={() => { this._openCouponTypes() }} className="px-4 bg-cgray-300 flex items-center justify-center absolute right-2 top-2 cursor-pointer" style={{ height: 40 }}>
                        <FormattedMessage id="lang_system" defaultMessage="lang_system" />
                    </div>

                </div>            
                }

                <div className="amounts">
                <span 
                    onClick={() => { this.subtractAmount() }}
                    className={classNames({
                    'active': false
                    },"width100john")}><i className="fas fa-minus"></i></span>       
                    <span 
                    onClick={() => { this.setState({showCouponNumpad: true}) }}
                    className={classNames({
                        'active': false
                        },"width100john")}>{coupon_bottom.bet_amount.toFixed(2)}</span>    
                          <span 
                          
                    onClick={() => { this.addAmount(coupon_bottom.bet_amount + 1) }}
                    className={classNames({
                        'active': false
                        },"width100john")}>   <i className="fas fa-plus"></i></span>                      
                </div>

                {/* <div className="bottom-row">
                    <span className="text-lg font-bold font-roboto"><FormattedMessage id="lang_bet_amount" defaultMessage="lang_bet_amount" /></span>
                    <span className="text-lg font-bold font-roboto">{coupon_bottom.bet_amount.toFixed(2)}</span>
                </div> */}

                {/* <div className="bottom-row">
                    <span><FormattedMessage id="lang_total_stake" defaultMessage="lang_total_stake" /></span>
                    {
                    coupon_bottom.coupon_number <= 1 ?
                    <span>{coupon_bottom.bet_amount.toFixed(2)}</span>
                    :
                    <span>{coupon_bottom.coupon_number} x {coupon_bottom.bet_amount.toFixed(2)} = {(coupon_bottom.bet_amount * coupon_bottom.coupon_number).toFixed(2)}</span>
                    }
                </div> */}

                <div className="bottom-row">
                    <span><FormattedMessage id="bets" defaultMessage="bets" /></span>
                    <span>{coupon.length}</span>
                </div>

                <div className="bottom-row">
                    <span><FormattedMessage id="lang_total_odds" defaultMessage="lang_total_odds" /></span>
                    <span>{coupon_bottom.total_rate.toFixed(2)}</span>
                </div>

                <div className="bottom-row">
                    <span><FormattedMessage id="lang_max_gain" defaultMessage="lang_max_gain" /></span>
                    <span>{(coupon_bottom.total_rate * coupon_bottom.bet_amount).toFixed(2) }</span>
                </div>

                {/* <div className="bottom-row">
                    <span><FormattedMessage id="lang_total" defaultMessage="lang_total" /></span>
                    <span>{coupon_bottom.max_gain.toFixed(2)}</span>
                </div>                         */}

                {/* <div onClick={() => { this.setState({lock: !lock}) }} className="odd-changes-check">
                    <div className="checkbox">
                        {
                        lock && (
                            <i className="fas fa-check" />
                        )
                        }
                    </div>
                    <span><FormattedMessage id="lang_i_accept_rate_changes" defaultMessage="lang_i_accept_rate_changes" /></span>
                </div> */}

<button 
    onClick={() => { 
        this._checkPrinterStatus()
    }} 
    disabled={coupon.length < 1}
    className="create-coupon">
    <FormattedMessage id="lang_create_coupon" defaultMessage="lang_create_coupon" />
</button>
            </div>            
            </>
            )
            }
            </div>
            
            {
            showCouponTypeModal && (
            <div className="coupon-types-cont">

                {
                combinations.map((item, index) => {
                    
                    const cselected = Boolean(selectedCombinations.indexOf(item) != -1)
                    if (item.combine <= Redux.getBankoCount()) return null

                    return (
                        <div key={index} 
                        onClick={() => { this.addOrRemoveCombination(item) }}
                        className={classNames({
                            'combination-item': true,
                            'selected': cselected,
                            "cursor-pointer": true
                        })}>
                            <span>{item.label}</span>
                            <span>{item.coupon_count}</span>
                        </div>        
                    )
                })
                }
                
                <button onClick={() => { this.confirmCombinations() }} className="confirm-coupon-types"><FormattedMessage id="lang_confirm" defaultMessage="lang_confirm" /></button>
                <button onClick={() => { this.setState({showCouponTypeModal: false}) }} className="close-coupon-types"><FormattedMessage id="lang_cancel" defaultMessage="lang_cancel" /></button>
            </div>
            )
            }

            {
            showCouponNumpad && (
            <div className="coupon-numpad-cont">
                <CouponNumpad onClose={() => { this.setState({showCouponNumpad: false}) }} maxStake={coupon_bottom.maxStake} minStake={coupon_bottom.minStake} keyword={coupon_bottom.bet_amount.toFixed(2)} />
            </div>
            )
            }

        </div>
        );
    }
}

const msp = (state) => {
  return {
      config: state.config,
      coupon_settings: state.coupon_settings,
      coupon: state.coupon,
      settings: state.settings,
      member: state.member,
      language: state.language,
      coupon_bottom: state.coupon_bottom,
      combinations: state.combinations,
      selected_combinations: state.selected_combinations,
      coupon_changed: state.coupon_changed,
      coupon_errors: state.coupon_errors,
      ps: state.printer_status,
      live_matches: state.live_matches
  }
}

export default connect(msp)(injectIntl(Coupon))
