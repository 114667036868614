import React from "react";
import { connect } from "react-redux";
// import { Redux } from "../../components";
// import moment from "moment";
// import _ from "lodash";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

class LiveMatchRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      schema: props.schema,
      z: props.z,
      l: props.l,
      oe: props.oe,
      _mid: props._mid,
      halfTime: props.halfTime,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps?.schema !== this.state.schema) {
      return true;
    }

    if (nextProps?._mid === this.state.item?.id) {
      return true;
    }

    if (nextState?._mid === this.state.item?.id) {
      return true;
    }

    return false;
  }

  _removeAnimate(mid, oid) {
    oid.an = "";
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ _mid: mid });
    }, 800);
  }

  componentWillUnmount() {
    if (this.timeout !== undefined) clearTimeout(this.timeout);
  }

  _setStatus(item) {
    if (item.ms.includes("pause")) {
      return <span>HT</span>;
    }
    if (item.sid === 1 && item.l) {
      return <span>{item.ti}'</span>;
    } else if (item.sid !== 1 && item.l) {
      return <span>{item.ms}</span>;
    } else {
      return <span dangerouslySetInnerHTML={{ __html: item.d }} />;
    }
  }

  render() {
    const { item, schema, z, l, oe } = this.props;

    return (
      <div className="w-full">
        <div
          className={classNames({
            "match-row-cont": !item.ht,
            "match-row-cont-fp": item.ht,
          })}
        >
          {schema.map((s, sindex) => {
            if (s.y === "o" && !s.ics) return null;
            return (
              <div
                key={sindex}
                data-box={z}
                
                data-col={l}
                className={classNames({
                  [`m-${s.y} `]: Boolean(
                    s.y !== "t" || (!item.ht && s.y === "t")
                  ),
                  [`m-t-2 `]: Boolean(s.y === "t" && item.ht),
                  [`i-c-${oe}`]: true,
                  [`${item.cls}`]: true,
                })}
              >
                {s.y === "d" && this._setStatus(item)}

                {s.y === "t" && (
                  <>
                    <div className="live-match-teams-score">
                      <div className="live-match-teams-flag">
                        <span>{item.tn}</span>
                        <span>
                          {item.cn}{" "}
                          {/*<img src={`https://s.betimg.com/ekol/uploads/bflags/${item.cid}.png`} />*/}
                        </span>
                      </div>

                      <div className="live-match-teams">
                        {item?.stt?.c?.r?.h > 0 && (
                          <span className="red-card-home" />
                        )}

                        {item?.stt?.c?.r?.a > 0 && (
                          <span className="red-card-away" />
                        )}

                        <div className="live-match-teams-home">
                          <span>{item.h}</span>
                        </div>

                        <div className="live-match-score">
                          {item?.scr ? (
                            <span>
                              {item.scr.h} : {item.scr.a}
                            </span>
                          ) : (
                            <span />
                          )}
                        </div>

                        <div className="live-match-teams-away">
                          <span>{item.a}</span>
                        </div>
                      </div>
                    </div>
                    {item.ht && (
                      <div className="live-match-fh-name">
                        <span className="live-row-priod-name">
                          <FormattedMessage
                            id="lang_first_period"
                            defaultMessage="lang_first_period"
                          />
                        </span>
                      </div>
                    )}
                  </>
                )}

                {s.y === "o" && s.ics && (
                  <>
                    <div className={`odd-group odd-group-${s.o.length}`}>
                      {s.o.map((_o, oindex) => {
                        let o = _o;

                        let g = undefined;
                        let j = undefined;

                        let _g = item?.o?.filter(
                          (x) =>
                            x.id === o.t &&
                            !x.iss &&
                            x.isv &&
                            x.o.find((y) => !y.iss && y.isv)
                        );

                       // console.log("_g", _g);

                        if (item.itie && o.t === 7 && _g?.length === 0) {
                          _g = item?.o?.filter((x) => x.id === 1);
                          let _s = schema.filter((x) => x.i === 1);
                          if (_s?.length > 0) {
                            _s = _s[0];
                            o = _s?.o[oindex] ?? _o;
                          }
                        }

                        if (_g?.length > 0) {
                          g = _g[0];

                          if (item.sid === 1) {
                            if (item.ith && o.t === 18) {
                              _g = _g.filter((x) => x.spv === 2.5);
                              if (_g.length > 0) {
                                g = _g[0];
                              }
                            } else if (o.t === 18) {
                              _g = _g.filter((x) => x.spv % 1 > 0);
                              if (_g.length > 0) {
                                g = _g[0];
                              }
                            }
                          }

                          const k = g?.o?.filter((x) => x.id === o.i);

                          if (k?.length > 0) {
                            j = k[0];
                          }
                        }

                        const st = j?.st ?? "locked";

                        return (
                          <div
                            key={`${j?.sid ?? oindex + 100_000}-${oindex}`}
                            className={classNames({
                              "live-odd-button-cont": true,
                            })}
                          >
                            {o.o === ".s" && (
                              <div className="odd-special">{g?.spv ?? "-"}</div>
                            )}

                            {o.o !== ".s" && st === "" && (
                              <div className="active-odd">{j.o}</div>
                            )}

                            {o.o !== ".s" && st === "locked" && (
                              <div className="locked-odd" />
                            )}

                            {o.o !== ".s" && st === "semi-locked" && (
                              <div className="semi-locked-odd">{j.o}</div>
                            )}

                            {o.o !== ".s" && st === "" && j?.an.length > 0 && (
                              <>
                                <span
                                  className={`fas market-animate-${j.an}`}
                                />
                                {this._removeAnimate(item.id, j)}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {s.hf && s.fh?.length > 0 && item.ht && (
                      <>
                        <div className="sep" />
                        <div className={`odd-group odd-group-${s.o.length}`}>
                          {s.hf &&
                            s.fh?.length > 0 &&
                            item.ht &&
                            s.fh.map((_o, oindex) => {
                              let o = _o;

                              let g = undefined;
                              let j = undefined;

                              let _g = item?.o?.filter(
                                (x) =>
                                  x.id === o.t &&
                                  !x.iss &&
                                  x.isv &&
                                  x.o.find((y) => !y.iss && y.isv)
                              );
                              if (
                                item.it &&
                                o.t === 999_999 &&
                                _g.length === 0
                              ) {
                                _g = item?.o?.filter((x) => x.id === 5518);
                                let _s = schema.filter((x) => x.i === 5518);
                                if (_s?.length > 0) {
                                  _s = _s[0];
                                  o = _s?.o[oindex] ?? _o;
                                }
                              }

                              if (_g?.length > 0) {
                                g = _g[0];

                                if (item.sid === 1) {
                                  if (item.ith && o.t === 5520) {
                                    _g = _g.filter((x) => x.spv === 0.5);
                                    if (_g.length > 0) {
                                      g = _g[0];
                                    }
                                  } else if (o.t === 5520) {
                                    _g = _g.filter((x) => x.spv % 1 > 0);
                                    if (_g.length > 0) {
                                      g = _g[0];
                                    }
                                  }
                                }

                                const k = g?.o?.filter((x) => x.id === o.i);

                                if (k?.length > 0) {
                                  j = k[0];
                                }
                              }

                              const st = j?.st ?? "locked";

                              return (
                                <div
                                  key={`${j?.Id ?? oindex + 100_000}-${oindex}`}
                                  className={classNames({
                                    "live-odd-button-cont": true,
                                  })}
                                >
                                  {o.o === ".s" && (
                                    <div className="odd-special">
                                      {g?.spv ?? "-"}
                                    </div>
                                  )}

                                  {o.o !== ".s" && st === "" && (
                                    <div className="active-odd">{j.o}</div>
                                  )}

                                  {o.o !== ".s" && st === "locked" && (
                                    <div className="locked-odd" />
                                  )}

                                  {o.o !== ".s" && st === "semi-locked" && (
                                    <div className="semi-locked-odd">{j.o}</div>
                                  )}

                                  {o.o !== ".s" &&
                                    st === "" &&
                                    j?.an?.length > 0 && (
                                      <>
                                        <span
                                          className={`fas market-animate-${j.an}`}
                                        />
                                        {this._removeAnimate(item.id, j)}
                                      </>
                                    )}
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}

                    {!s.hf && item.ht && (
                      <>
                        <div className="sep" />
                        <div className={`odd-group odd-group-${s.o.length}`}>
                          {s.o.map((o, oindex) => {
                            return (
                              <div
                                key={oindex + 200_000}
                                className={classNames({
                                  "live-odd-button-cont": true,
                                })}
                              >
                                <div className="locked-odd" />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
    _mid: state._mid,
    live_matches: state.live_matches,
  };
};

export default connect(msp)(LiveMatchRow);
