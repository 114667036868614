import React from "react";

class MatchTitle extends React.Component {
  static defaultProps = {
    showTitle: false,
    index: 0,
  };

  render() {
    const { showTitle, schema, flag, title, z, l } = this.props;

    if (!showTitle) return null;

    return (
      <div className="match-title-cont" style={{height:"20px"}}>
        {schema.map((s, sindex) => {
          return (
            <div
              key={sindex}
              data-box={z + 1}
              data-col={l}
              className={`t-${s.y} t-o-${s.o?.length ?? 0}`}
            >
              {s.y == "d" && (
                <img
                  src={`https://s.betimg.com/ekol/uploads/bflags/${flag}.png`}
                />
              )}

              {s.y == "t" && title}

              {s.y == "o" && <div></div>}

              {s.y == "s" && <div></div>}

              {s.y == "a" && <div></div>}
            </div>
          );
        })}
      </div>
    );
  }
}

export default MatchTitle;
