import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown, CFormatDate, CButton } from '../../../../components'
import { FormattedMessage, injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import Swal from 'sweetalert2/src/sweetalert2.js'
import moment from 'moment'

let divHeight = 0
let pages = 1
let activePage = 1
let limit = 0

class AdminTickets extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient();
    this.state = {
      coupons: [],
      showDetail: false
    }
  }

  componentDidMount() {

    setTimeout(() => {
      divHeight = this.divElement.clientHeight - 50.75
      let b = Math.ceil(divHeight / 67.8)
      limit = b - 1
      pages = 1
      activePage = 1

      this._getCoupons()

    }, 200)

  }

  _getCoupons() {

    this.wc.post(constants.uris.ucps,
      {
        limit: limit,
        order: (limit * (activePage - 1))
      },
      {
        setReqId: true,
        setUserId: true,
        setLang: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {

          let a = response.results
          let c = Math.ceil(response.totalCount / (limit))

          pages = c;

          this.setState({ activeTab: 1, coupons: a })
        } else {
          Redux.showToast(
            "error",
            response.msg
          )
        }

      })

  }

  _getCouponDetail(ticketId) {

    this.wc.post(constants.uris.ucpd,
      {
        ticketId
      },
      {
        setReqId: true,
        setUserId: true,
        setLang: true
      }).then((response) => {
        // console.log(response)
        if (response.status) {
          Redux.showTicketDetail(response)
        } else {
          Redux.showToast(
            "error",
            response.msg
          )
        }

      })

  }

  _nextPage() {

    if (activePage < pages) {
      activePage = activePage + 1
      this._getCoupons()
    }

  }

  _previousPage() {

    if (activePage > 1) {
      activePage = activePage - 1
      this._getCoupons()
    }

  }
  async _sendDirectPrint(id) {



    Redux.addPrintedTicket(id)

    let url = constants.prints.winprint.replace("{{couponId}}", id).replace("{{lang}}", "en")

   


 try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
           // console.log("Response data -->", data);
           if(data.status)
            {

            }
        } catch (error) {
            console.error("Fetch error: ", error);
        }

      // PRINT COUPON
        // window.electronAPI.ipcRenderer.send("printUrl", {
        //     url,
        //     filename: `ticket_coupon_${id}`,
        //     type: 'ticket',
        //     couponId: id
        // })
        // PRINT COUPON 
        

    }
  _resetAccount() {
    Swal.fire({
      title: this.props.intl.formatMessage({ id: "lang_reset_account", defaultMessage: "lang_reset_account" }),
      text: this.props.intl.formatMessage({ id: "lang_are_you_sure_you_want_to_reset_the_account", defaultMessage: "lang_are_you_sure_you_want_to_reset_the_account" }),
      icon: 'warning',
      confirmButtonText: this.props.intl.formatMessage({ id: "lang_approve", defaultMessage: "lang_approve" }),
      denyButtonText: this.props.intl.formatMessage({ id: "lang_cancel", defaultMessage: "lang_cancel" }),
      showDenyButton: true,
    }).then((response) => {
      // console.log(response)
      if (response.isConfirmed) {

        this.wc.post(constants.uris.rsta,
          {
            siteType: "1"
          },
          {
            setReqId: true,
            setUserId: true,
            setLang: true
          }).then((response) => {
            // console.log(response)

            if (response.status) {
              Redux.showToast("success", response.msg)
              this._getUserBills()
            } else {
              Redux.showToast("error", response.msg)
            }

          }).catch((err) => {
            // console.log(err)
          })

      }
    })
  }
  // STATUS   -2    CANCEL    #ffab75
  // STATUS   1     WON       #cfffca
  // STATUS   -1    LOST      #ffd5d5

  render() {
    const { coupons, showDetail } = this.state
    return (
      <>
        <div ref={(ref) => { this.divElement = ref }} className="manager-content relative">
          <table className="ticket-result-table">
            <thead>
              <tr>
                <th><FormattedMessage id="lang_coupon_no" defaultMessage="lang_coupon_no" /></th>
                <th><FormattedMessage id="lang_date" defaultMessage="lang_date" /></th>
                <th><FormattedMessage id="lang_bet" defaultMessage="lang_bet" /></th>
                <th><FormattedMessage id="lang_payment" defaultMessage="lang_payment" /></th>
                <th><FormattedMessage id="lang_possible_gain" defaultMessage="lang_possible_gain" /></th>
                <th><FormattedMessage id="lang_bonus" defaultMessage="lang_bonus" /></th>
                <th><FormattedMessage id="lang_total" defaultMessage="lang_total" /></th>
                <th><FormattedMessage id="lang_type" defaultMessage="lang_type" /></th>
                <th><FormattedMessage id="lang_status" defaultMessage="lang_status" /></th>
                <th><FormattedMessage id="lang_payment_status" defaultMessage="lang_payment_status" /></th>
                <th><FormattedMessage id="lang_detail" defaultMessage="lang_detail" /></th>
                <th><FormattedMessage id="lang_cancel" defaultMessage="lang_cancel" /></th>
                <th><FormattedMessage id="lang_print" defaultMessage="lang_print" /></th>
              </tr>
            </thead>
            <tbody>
              {
                coupons.map((item, index) => {
                  return (
                    <tr className={classNames({
                      'st-cancel': Boolean(item.status == -2),
                      'st-won': Boolean(item.status == 1),
                      'st-lost': Boolean(item.status == -1),
                      'st-waiting': Boolean(item.status == 2)
                    })} key={index}>
                      <td>{item?.ticketId ?? 0}</td>
                      <td><CFormatDate date={item.ticketDate} /></td>
                      <td>{item?.totalStake ?? 0}</td>
                      <td>{item?.income ?? 0}</td>
                      <td>{item?.maxPay ?? 0}</td>
                      <td>{item?.bonusPay ?? 0}</td>
                      <td>{item?.maxPay ?? 0}</td>
                      <td><FormattedMessage id={Redux.getCouponTypeName(item.ticketType)} defaultMessage={Redux.getCouponTypeName(item.ticketType)} /> ({item.totalTickets})</td>
                      <td><FormattedMessage id={Redux.getCouponStatusName(item.status)} defaultMessage={Redux.getCouponStatusName(item.status)} /></td>
                      <td><FormattedMessage id={item.paid == 1 ? "lang_paid" : "lang_waiting"} defaultMessage={item.paid == 1 ? "lang_paid" : "lang_waiting"} /></td>
                      <td>
                        <button onClick={() => { this._getCouponDetail(item.id) }} className="detail-button">
                          <i className="fas fa-search mr-2" />
                          <FormattedMessage id="lang_detail" defaultMessage="lang_detail" /></button>
                      </td>
                      <td>{item?.canceled != 0 ? item.canceled : ""}</td>
                      <td>
                        <button onClick={() => { this._sendDirectPrint(item?.id) }} className="print-button">
                          <i className="fas fa-print mr-2" />
                          <FormattedMessage id="lang_print" defaultMessage="lang_print" /></button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          {
            showDetail && (
              <div className="w-full h-full absolute top-0 left-0 bg-cgray-800 bg-opacity-70">

              </div>
            )
          }

        </div>
        <div className="bottom-pagination-cont">
          <CButton width="w-1/3" icon={true} iconName="fa-chevron-left" onClick={() => { this._previousPage() }} />
          <div className="live-pagination-cont" style={{ width: "33%" }}>
            {activePage} / {pages}
          </div>
          <CButton width="w-1/3" icon={true} iconName="fa-chevron-right" onClick={() => { this._nextPage() }} />
        </div>
      </>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config
  }
}

export default connect(msp)(injectIntl(AdminTickets))