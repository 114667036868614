import React from 'react'
import ConfigWindow from './project/windows/config'
import MainWindow from './project/windows/main'
import { ConfigIntlProviderWrapper } from './project/components/intl-providers/config-intl-provider'
import { MainIntlProviderWrapper } from './project/components/intl-providers/main-intl-provider'

class App extends React.Component {

  componentDidMount() {
    
  }

  render() {




  
      return (
        <MainIntlProviderWrapper>
          <MainWindow />
        </MainIntlProviderWrapper>
      );
    

  }
}

export default App;
